import * as React from "react";
import VerticalMenu from "../../components/VerticalMenu";
import HorizontalMenu from "../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import PageTitle from "../../components/PageComponents/PageTitle";
import Paragraph from "../../components/PageComponents/Paragraph";
import ParagraphTitle from "../../components/PageComponents/ParagraphTitle";
import AppButton from "../../components/AppButton";
import { pageStyles, pageContent } from "../../styleConstants";

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <div style={pageContent}>
        <VerticalMenu page={10} />

        <Container maxWidth="md" style={{ wordWrap: "break-word" }}>
          <PageTitle text="Data library API" />

          <Paragraph text="The data library API allows you to fetch your purchased dataset from GLOBHE's data library. In the browser, you can download your datasets directly from your page after you purchase them. By using the API, you can automate this task and place your datasets in your servers for a quicker delivery." />
          <Paragraph text="Note that data library API does not allow you to purchase datasets. You need to purchase your datasets from your account and pay directly before you can use this API." />
          <ParagraphTitle text="Purchase datasets from the data library" />
          <Paragraph text="To purchase datasets from GLOBHE's data library, sign in to your account and navigate to the data library." />
          <AppButton
            look="black"
            label="Sign in to your account"
            url="https://app.globhe.com/crowddroning/client/sign-in/"
            externalLink
            targetNewWidow
          />
        </Container>
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
